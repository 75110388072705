import React, { useState, useEffect, useRef } from "react";
import "./Nav.css";
import { Link } from "react-router-dom";
import logo from "../../resource/logo.png";
import CodeOfEthics from "../../resource/PDF/Code Of Ethics .pdf";
import FairpacticeCode from "../../resource/PDF/Fair Practice Code.pdf";
import Clientprotection from "../../resource/PDF/The-Client-Protection-Principles_EN.pdf";
import { useLocation } from "react-router-dom";

const Nav = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [expandMenu, setExpandMenu] = useState();
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const location = useLocation();
  const mobileMenuRef = useRef(null);
  const mobileIconRef = useRef(null);

  const expandMenuFn = (menuName) => {
    if (expandMenu === menuName) {
      setExpandMenu("");
      return;
    }
    setExpandMenu(menuName);
  };

  const useEffectAfterFirstRender = (effect, dependency) => {
    const hasMounted = useRef(false);
    useEffect(() => {
      if (hasMounted.current) {
        effect();
      } else {
        hasMounted.current = true;
      }
    }, dependency);
  };

  useEffectAfterFirstRender(() => {
    mobileMenuRef.current.classList.remove("phone_menu_item_active");
    mobileIconRef.current.classList.remove("fa-xmark");
  }, [location.pathname]);

  const navScrollHandler = () => {
    const currentScrollPos = window.scrollY;
    const isScrollingDown = prevScrollPos < currentScrollPos;
    if (isScrollingDown) {
      if (mobileMenuRef.current.classList.contains("phone_menu_item_active")) {
        mobileMenuRef.current.classList.remove("phone_menu_item_active");
        mobileIconRef.current.classList.remove("fa-xmark");
      }
    }
    setPrevScrollPos(currentScrollPos);
  };

  // useEffect(() => {
  //   navScrollHandler()
  // }, [window.scrollY])

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 30) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    navScrollHandler()

    }
    // if (window.innerWidth >= 769) {
    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const scrollBtn = document.getElementById("mybutton");
    window.onscroll = function() {
      scrollFunction();
    };

    function scrollFunction() {
      if (
        document.body.scrollTop > 500 ||
        document.documentElement.scrollTop > 500
      ) {
        scrollBtn.style.display = "block";
      } else {
        scrollBtn.style.display = "none";
      }
    }

    scrollBtn.onclick = function() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    };
  }, []);

  useEffect(() => {
    let Menu_open = document.querySelector(".menu_btn");
    let Mobile_menu = document.querySelector(".phone_menu_item");
    Menu_open.onclick = function() {
      Mobile_menu.classList.toggle("phone_menu_item_active");
      Menu_open.classList.toggle("fa-xmark");
    };
  });

  return (
    <>
      <div className="head">
        <div className="container">
          <div className="nav-items">
            <div className="head_left">
              <Link to="/Press">On Press</Link>
              <Link to="/GeographicalPresence">Our Offices</Link>
              <Link to="/FAQ">FAQ</Link>
            </div>
            <div className="head_right">
              <Link to="tel:03335081849">
                <div className="grievance-number">
                  <span
                    style={{
                      fontSize: "1.5rem",
                      color: "white",
                      marginRight: "2px",
                    }}
                  >
                    <i className="fa-solid fa-headset"></i>
                  </span>
                  <p style={{ color: "white" }}>Customer Grievance Number : </p>
                  <span className="icon_padding"> (033) 3508-1849</span>
                </div>
              </Link>
              <i className="fa-solid fa-grip-lines-vertical"></i>
              <Link
                to="https://mail.google.com/mail/?view=cm&fs=1&to=contactus@sampurna.co.in"
                target="_blank"
              >
                <i className="fa-regular fa-envelope"></i>
                <span className="icon_padding">contactus@sampurna.co.in</span>
              </Link>
              <i className="fa-solid fa-grip-lines-vertical"></i>
              <Link to="tel:6292276444">
                <i className="fa-solid fa-phone"></i>
                <span className="icon_padding">+91 62922-76444</span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div id="mybutton" className="scroll_top">
        <i className="fa-solid fa-arrow-up"></i>
      </div>

      <nav className={isSticky ? "nav fixed" : "nav"}>
        <div className="container">
          <div className="brand">
            <Link to="/">
              <img className="logowhite" src={logo} alt="Sampurna" />
            </Link>
          </div>
          <div className="menu">
            <ul>
              <li className="menu_item_">
                <Link className="menu_link">About Us</Link>
                <i className="fa-solid changei fa-caret-down"></i>
                <ul>
                  <li>
                    <Link to="/Overview">Overview</Link>
                  </li>
                  <li>
                    <Link to="/Vision">Vision & Mission</Link>
                  </li>
                  <li>
                    <Link to="/Background">Organization Background</Link>
                  </li>
                  <li>
                    <Link to="/Network">Our Networks</Link>
                  </li>
                </ul>
              </li>
              <li className="menu_item_">
                <Link className="menu_link">Our Services</Link>
                <i className="fa-solid changei fa-caret-down"></i>
                <ul>
                  <li>
                    <Link to="/Microfinance">Microfinance</Link>
                  </li>
                  <li>
                    <Link to="/Skills">Skills Development</Link>
                  </li>
                  <li>
                    <Link to="/Socialservice">Social Services</Link>
                  </li>
                </ul>
              </li>
              <li className="menu_item_">
                <Link to="/Gallery" className="menu_link">
                  Gallery
                </Link>
              </li>
              <li className="menu_item_">
                <Link className="menu_link">Governance</Link>
                <i className="fa-solid changei fa-caret-down"></i>
                <ul>
                  <li>
                    <Link to={CodeOfEthics} target="_blank">
                      Code Of Ethics
                    </Link>
                  </li>
                  <li>
                    <Link to={FairpacticeCode} target="_blank">
                      Fair Practice Code
                    </Link>
                  </li>
                  <li>
                    <Link to={Clientprotection} target="_blank">
                      Client Protection Principles
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="menu_item_">
                <Link to="/Contact" className="menu_link">
                  Get In Touch
                </Link>
              </li>
            </ul>
          </div>
          <div className="menu-btn">
            <i className="fa-solid menu_btn fa-bars" ref={mobileIconRef}></i>
          </div>
          {/* ----------------------Phone Menu List------------------------- */}
          <div className="phone_menu_item" ref={mobileMenuRef}>
            <Link onClick={() => expandMenuFn("aboutus")}>
              <strong>ABOUT US</strong>
              <i className={`fa-solid fa-caret-down ${expandMenu === 'aboutus' ? "rotate-caret" : ""}`}></i>
            </Link>
            <div
              className={`${
                expandMenu === "aboutus" ? "show-menu" : "hide-menu"
              }  `}
            >
              <Link to="/Overview">Overview</Link>
              <Link to="/Vision">Vision & Mission</Link>
              <Link to="/Background">Organization Background</Link>
              <Link to="/Network">Lender & Partner</Link>
            </div>

            <Link onClick={() => expandMenuFn("ourservice")}>
              <strong>Our Services</strong>
              <i className="fa-solid fa-caret-down"></i>
            </Link>
            <div
              className={`${
                expandMenu === "ourservice" ? "show-menu" : "hide-menu"
              } `}
            >
              <Link to="/Microfinance">Microfinance</Link>
              <Link to="/Skills">Skills Development</Link>
              <Link to="/Socialservice">Social Services</Link>
            </div>
            <Link to="/Gallery">
              <strong>GALLERY</strong>
            </Link>
            <Link onClick={() => expandMenuFn("governance")}>
              <strong>GOVERNANCE</strong>
              <i className="fa-solid fa-caret-down"></i>
            </Link>
            <div
              className={`${
                expandMenu === "governance" ? "show-menu" : "hide-menu"
              }`}
            >
              <Link to={CodeOfEthics} target="_blank">
                Code Of Ethics
              </Link>
              <Link to={FairpacticeCode} target="_blank">
                Fair Practice Code
              </Link>
              <Link to={Clientprotection} target="_blank">
                Client Protection Principles
              </Link>
            </div>
            <Link to="/Contact">
              <strong>Get in Touch</strong>
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Nav;
