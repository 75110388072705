import React, { useEffect } from "react";
import "./App.css";
import NavBar from "./Components/Navbar_component/Nav.js";
import Footer from "./Components/Footer_component/Footer.js";
import Home from "./Components/Pages/Home.js";
import Press from "./Components/Pages/Press.js";
import Offices from "./Components/Pages/Offices.js";
import FAQ from "./Components/Pages/FAQ.js";
import Overview from "./Components/Pages/Overview.js";
import Vision from "./Components/Pages/Vision.js";
import Background from "./Components/Pages/Background.js";
import Microfinance from "./Components/Pages/Microfinance.js";
import Skills from "./Components/Pages/Skills.js";
import Socialservice from "./Components/Pages/Socialservice.js";
import Gallery from "./Components/Pages/Gallary.js";
import Contact from "./Components/Pages/Contact.js";
import Network from "./Components/Pages/Lender.js";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import PrivacyPolicy from "./Components/Pages/PrivacyPolicy";

function App() {

  /** Disabling Right Click */
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault(); // Prevent the default right-click behavior
    };

    // Add event listener to the document for the contextmenu event
    document.addEventListener("contextmenu", handleContextMenu);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []); // Empty dependency array ensures that the effect runs only once when the component mounts

  /**Disabling Keyboard Shortcuts */

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Disable Ctrl+A (Cmd+A on Mac)
      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === "a" || event.key === "A")
      ) {
        event.preventDefault();
      }

      // Disable Ctrl+S (Cmd+S on Mac)
      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === "s" || event.key === "S")
      ) {
        event.preventDefault();
      }

      // Disable Ctrl+X (Cmd+X on Mac)
      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === "x" || event.key === "X")
      ) {
        event.preventDefault();
      }

      // Disable Ctrl+C (Cmd+C on Mac)
      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === "c" || event.key === "C")
      ) {
        event.preventDefault();
      }

      // Disable Ctrl+Shift+I (Cmd+Shift+I on Mac)
      if (
        (event.ctrlKey || event.metaKey) &&
        event.shiftKey &&
        (event.key === "i" || event.key === "I")
      ) {
        event.preventDefault();
      }
    };

    // Add event listener to the document for the keydown event
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []); // Empty dependency array ensures that the effect runs only once when the component mounts

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LayoutWrapper><Home /></LayoutWrapper>} />
        <Route path="/Press" element={<LayoutWrapper><Press /></LayoutWrapper>} />
        <Route path="/GeographicalPresence" element={<LayoutWrapper><Offices /></LayoutWrapper>} />
        <Route path="/FAQ" element={<LayoutWrapper><FAQ /></LayoutWrapper>} />
        <Route path="/Overview" element={<LayoutWrapper><Overview /></LayoutWrapper>} />
        <Route path="/Vision" element={<LayoutWrapper><Vision /></LayoutWrapper>} />
        <Route path="/Background" element={<LayoutWrapper><Background /></LayoutWrapper>} />
        <Route path="/Microfinance" element={<LayoutWrapper><Microfinance /></LayoutWrapper>} />
        <Route path="/Skills" element={<LayoutWrapper><Skills /></LayoutWrapper>} />
        <Route path="/Socialservice" element={<LayoutWrapper><Socialservice /></LayoutWrapper>} />
        <Route path="/Gallery" element={<LayoutWrapper><Gallery /></LayoutWrapper>} />
        {/* Removed Board and Management page as instructed */}
        {/* <Route path="/Board" element={<LayoutWrapper><Board /></LayoutWrapper>} />
        <Route path="/Management" element={<LayoutWrapper><Management /></LayoutWrapper>} /> */}
        <Route path="/Contact" element={<LayoutWrapper><Contact /></LayoutWrapper>} />
        <Route path="/Network" element={<LayoutWrapper><Network /></LayoutWrapper>} />
        <Route path="/PrivacyPolicy" element={<LayoutWrapper><PrivacyPolicy /></LayoutWrapper>} />
        <Route path="*" element={<NotFound />} /> {/* Handle 404 error */}
      </Routes>
    </BrowserRouter>
  );
}

function LayoutWrapper({ children }) {
  const location = useLocation();
  const hideNavBarFooter = location.pathname === "*"; // Hide NavBar and Footer for 404 error page

  return (
    <div>
      {!hideNavBarFooter && <NavBar />}
      {children}
      {!hideNavBarFooter && <Footer />}
    </div>
  );
}

function NotFound() {
  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100vw",
      height: "100vh",
    }}>
      <h1>404 - Page Not Found</h1>
      <p>The requested page does not exist.</p>
      <p>Please return to the <Link style={{
        color: "var(--magenda)",
        textTransform: "uppercase",
        fontWeight :"600"
      }} to="/">home page</Link>.</p>
    </div>
  );
}

export default App;
