import React, { useState, useEffect } from "react";
import "./GallaryComp.css";
import  useModalBackHandler  from '../../custom-hooks/useModelBackHandler'

import IMG1 from "../../resource/Gallery/_BID5422.png";
import IMG2 from "../../resource/Gallery/_BID5453.png";
import IMG3 from "../../resource/Gallery/_BID5492.png";
import IMG4 from "../../resource/Gallery/_BID5495.png";
import IMG5 from "../../resource/Gallery/_BID5525.png";
import IMG6 from "../../resource/Gallery/_BID5532.png";
import IMG7 from "../../resource/Gallery/_BID5555.png";
import IMG8 from "../../resource/Gallery/_BID5588.png";
import IMG9 from "../../resource/Gallery/20220912_175305.jpg";
import IMG13 from "../../resource/Gallery/IMG_2358.JPG";
import IMG14 from "../../resource/Gallery/IMG_2364.JPG";
import IMG15 from "../../resource/Gallery/IMG_3897 - Copy (2).JPG";
import IMG17 from "../../resource/Gallery/SAM_0033.JPG";
import IMG18 from "../../resource/Gallery/SAM_0131.JPG";
import IMG19 from "../../resource/Gallery/SAM_0165.JPG";
import IMG21 from "../../resource/Gallery/SAM_1069.JPG";
import IMG22 from "../../resource/Gallery/SAM_1079.JPG";
import IMG23 from "../../resource/Gallery/SAM_1237.JPG";
import IMG24 from "../../resource/Gallery/SAM_1291.JPG";
import IMG25 from "../../resource/Gallery/SAM_1300.JPG";
import IMG26 from "../../resource/Gallery/WhatsApp Image 2022-11-05 at 21.51.05 (1).jpeg";
import IMG27 from "../../resource/Gallery/WhatsApp Image 2022-11-16 at 11.37.20.jpeg";
import IMG29 from "../../resource/Gallery/WhatsApp Image 2022-12-19 at 20.13.02.jpeg";
import IMG31 from "../../resource/Gallery/WhatsApp Image 2022-12-19 at 20.13.31.jpeg";
import IMG32 from "../../resource/Gallery/WhatsApp Image 2022-12-19 at 20.14.35.jpeg";
import IMG33 from "../../resource/Gallery/WhatsApp Image 2022-12-19 at 20.18.59.jpeg";
import IMG34 from "../../resource/Gallery/img34.jpg";
import IMG35 from "../../resource/Gallery/img35.jpg";
import IMG36 from "../../resource/Gallery/img36.jpg";
import IMG37 from "../../resource/Gallery/img37.jpg";
import IMG38 from "../../resource/Gallery/img38.jpg";


const GallaryComp = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupIndex, setPopupIndex] = useState(0);

  const isOpenModal = () => setShowPopup(true);
  const closeModal = () => setShowPopup(false);

  // useModalBackHandler(showPopup,closeModal)

  const images = [
    {
      src: IMG38,
      title:
        "Poushali Banerjee mesmerizes the crowd with her soulful performance, every note resonating with passion and grace.",
    },
    {
      src: IMG34,
      title:
        "All our Area Managers and Regional Managers sharing the stage, enjoying a moment of unity and fellowship. Here is to teamwork and leadership!",
    },
    {
      src: IMG37,
      title:
        "Poushali Banerjee captivates the crowd, forging a heartfelt connection in the midst of her soulful performance.",
    },
    {
      src: IMG2,
      title:
        "Lovely model ISHIKA SHAW, who is also a Collector Motivator, Bagbazar(Sampurna) posing on ramp for the fashion show organised at our Employee Meet, 2023",
    },
    {
      src: IMG22,
      title:
        "From friends to fellow Area Managers (MF) Partha Sarathi Roy (L) and Avishek Seal (R) during the celebration of Diwali at Sampurna",
    },
    {
      src: IMG33,
      title:
        "Mr. Abhijit Bera, MD and CEO (Sampurna) visiting a partner's house while he was on South 24 Parganas’ field visit",
    }, // 4
    {
      src: IMG5,
      title:
        " Subhendu Naskar, Collector Motivator of Baruipur branch and Khusi Bhattacharya, Collector Motivator of Howrah Maidan branch posing during Florafuse, a fashion show organised at the Employee Meet, 2023",
    },
    {
      src: IMG25,
      title:
        "Mr. Abhijit Bera, MD and CEO, Sampurna, giving his keynote speech at the Skills Meet, 2022",
    },

    {
      src: IMG18,
      title:
        " Sampurna employees in a small cake cutting ceremony at the Foundation Day celebration, 2022",
    },
    {
      src: IMG3,
      title:
        "Utsab Chakraborty, Accountant, Sampurna posing at Florafuse, a fashion show organised on the day of our Employee Meet, 2023",
    }, // 10 no   24
    {
      src: IMG36,
      title:
        "Our MD Sir, Abhijit Bera with a cheerful smile, felicitated the renowned singer Poushali Banerjee. 🌟🎤 Such moments of recognition and joy inspire us all!",
    },
    {
      src: IMG9,
      title:
        "Our Area Managers (Micro Finance) Partha Sarathi Roy, Subir Sarkar and Alpana Sil (R-L) during a business trip to Lucknow",
    },
    {
      src: IMG21,
      title:
        "The women folk of Sampurna's Head Office all decked up for Durga Puja, 2022. From Left to right you can see Shibani Majumder (Office Assistant), Alpana Sil (Area Manager- MF), Arati Shaw (Operation Manager- MF), Ruma Deb (MIS Manager), Indira Roy (HR Manager), Nabonita Biswas (Area Manager- MF), Suparna Das (Operation Manager- VT), Mousumi Majumdar (Finance Manager) ",
    },
    {
      src: IMG23,
      title:
        "Suparna Das, Operation Manager- VT, Sampurna sharing her precious words during the Skills Meet, 2022",
    },
    {
      src: IMG26,
      title:
        "The entire business team gathered at the Regional Meet (Kolkata), 2022",
    },
    {
      src: IMG19,
      title:
        "Shireen Akhtar, an employee of M-Swasth performing a check up at the Free Health Checkup Camp organised on our Foundation Day, 2022",
    },
    {
      src: IMG15,
      title:
        " Pratyusha Chatterjee, Communication Manager, Sampurna, receiving special participation honour from Mr. Abhijit Bera, MD and CEO of Sampurna",
    }, // no 7
    {
      src: IMG35,
      title:
        "Cheers to success and growth! Recently promoted employees take the stage, celebrating their achievements with joy and solidarity.",
    },
    {
      src: IMG14,
      title:
        " Two Delegates, Alain Levy (Head of Microfinance and Social entrepreneurship: America and Asia) and Mr. Siddharth Sagar (Assistant Vice President, Finan cial Institutions Coverage), from BNP Paribas, visited Sampurna in the month of September, 2022. Their focus was to visit the field and witness the work Sampurna has been doing with its partners. They interacted with the partners from our Bagabazar field area and sought their feedback on how Sampurna along with BNP -’s contribution has changed lives of thousands through socially beneficial projects.",
    },
    {
      src: IMG4,
      title:
        "Susmita Karmakar, a collector motivator of Bondelgate branch, looking like a diva at Florafuse, a fashion show organised on the day of our Employee Meet, 2023",
    }, // 10 n  33
    {
      src: IMG24,
      title:
        "Arati Shaw, Operation Manager- MF, Sampurna sharing her precious words during the Skills Meet, 2022",
    }, // 9N    3
    {
      src: IMG27,
      title:
        " Mr. Abhijit Bera, MD and CEO, Sampurna interacting with students while visiting the Canning branch",
    },
    {
      src: IMG13,
      title:
        "Alain Levy (Head of Microfinance and Social entrepreneurship: America and Asia) being felicitated by Mrs. Jhuma Kolay (Branch Manager, Sampurna Financial Services Pvt Ltd) while on a visit to one of Sampurna’s urban fields.",
    },
    {
      src: IMG7,
      title:
        "Basudev Roy, Branch Manager (Bajkul) and Fatma Khatoon, Faculty (Makeup and Beauty) were the show stoppers of Florafuse, a fashion show organised at the Employee Meet, 2023.",
    },
    {
      src: IMG8,
      title:
        "Mr. Abhijit Bera, MD and CEO, Sampurna, giving his keynote speech at the Employee Meet, 2023",
    },
    {
      src: IMG35,
      title:
        "Cheers to success and growth! Recently promoted employees take the stage, celebrating their achievements with joy and solidarity.",
    },
    {
      src: IMG31,
      title:
        "Mr. Abhijit Bera, MD and CEO (Sampurna) visiting a partner’s house while he was on South 24 Parganas’ field visit",
    },
    {
      src: IMG17,
      title:
        "Mr. Sudhanshu Dash (Regional Head, BC Channel: East and North), Mr. Abhijit Bera (MD and CEO, Sampurna) and Mr. Sibu KA (Vice President and Head – BCV) at Legends of ESAF where Sampurna employees were appreciated for their notice-worthy performance.",
    },
    {
      src: IMG32,
      title:
        " Mr. Abhijit Bera, MD and CEO (Sampurna) in a discussion with partners to get a realty fact check while he was on South 24 Parganas’ field visit",
    },
    {
      src: IMG6,
      title:
        "Sumit Adhikari (Faculty: Computer) and Anindita Mukherjee (Faculty: Makeup and Beauty) don their look at Florafuse, a fashion show organised on the day of our Employee Meet, 2023",
    },
    {
      src: IMG29,
      title:
        "Mr. Abhijit Bera, MD and CEO (Sampurna) exploring the area for experiencing living condition of partners while he was on South 24 Parganas’ field visit",
    },
    {
      src: IMG1,
      title:
        "Saugata Banerjee (Branch Manager, Seakhala) with his signature Indo-western look at Florafuse, a fashion show organised on the day of our Employee Meet, 2023",
    },
  ];
  
  const handleImageClick = (event, index) => {
    setShowPopup(true);
    setPopupIndex(index);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleNext = () => {
    setPopupIndex((popupIndex + 1) % images.length);
  };

  const handlePrevious = () => {
    setPopupIndex((popupIndex + images.length - 1) % images.length);
  };

  return (
    <>
      <div className="gallery container">
        <div className="image-container">
          {images.map((item, index) => (
            <div
              className="image-box"
              key={index}
              onClick={(event) => handleImageClick(event, index)}
            >
              <img src={item.src} className="image-item" />
            </div>
          ))}
        </div>
        {showPopup && (
          <div className="gallery-popup-img">
            <div className="popup-main">
              <span onClick={handleClosePopup} className="clone-btn">&times;</span>
              <button onClick={handlePrevious}>
                <i className="fa-solid fa-chevron-left"></i>
              </button>
              <div className="popup-content">
                <img src={images[popupIndex].src} className="viewed-image" alt="pic" />
                <p>{images[popupIndex].title}</p>
              </div>
              <button onClick={handleNext}>
                <i className="fa-solid fa-chevron-right"></i>
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default GallaryComp;
