import React, { useState } from "react";
import "./ContactComp.css";
import { Link } from "react-router-dom";
import { useForm } from 'react-hook-form'

const ContactComp = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const { register, handleSubmit, formState : { errors } } = useForm()

  // const sendMail = async (e) => {
  //   e.preventDefault();
  //   const res = await fetch("http://localhost:5500/register", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       email,
  //       name,
  //       number,
  //       message,
  //     }),
  //   });
  //   const data = await res.json();
  //   if (data.status === 401 || !data) {
  //     // console.log("Error");
  //     toast.error("Email not send!", {
  //       position: toast.POSITION.TOP_CENTER,
  //     });
  //   } else {
  //     // console.log("Email send");
  //     toast.success("Email send!", {
  //       position: toast.POSITION.TOP_CENTER,
  //     });
  //     setEmail("");
  //     setName("");
  //     setNumber("");
  //     setMessage("");
  //   }
  // };

  const sendMail = handleSubmit((data) => {
    console.log(data)
    console.log(errors)
  })


  return (
    <>
      <section className="contact">
        <div className="container">
          {/* cotact us form */}
          <div className="contact-left">
            <h1>Contact Us</h1>
            <p>
              Want to work with us or need more details about{" "}
              <strong>SAMPURNA</strong>.
            </p>
            <form id="contact-form">
              <div className="con-name">
                <div className="field name">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    onChange={(e) => setName(e.target.value)}
                    { ...register("name", { required : true, minLength : 3 }) }
                    placeholder="Your Name"
                  />
                </div>
                <div className="field number">
                  <input
                    type="number"
                    name="number"
                    id="number"
                    onChange={(e) => setNumber(e.target.value)}
                    placeholder="Your Number"
                    required
                  />
                </div>
              </div>
              <div className="con-email">
                <input
                  type="email"
                  name="email"
                  id="email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your Email"
                  required
                />
              </div>
              <div className="con-textarea">
                <textarea
                  name="message"
                  id="massage"
                  onChange={(e) => setMessage(e.target.value)}
                  cols="30"
                  rows="8"
                  placeholder="Describe your message"
                  required
                ></textarea>
              </div>
              <div className="button">
                <button type="submit" onClick={sendMail}>
                  Send Massage
                </button>
              </div>
            </form>
          </div>

          {/* contact us details */}
          <div className="contact-right">
            <div className="right-container">
              <div className="address">
                <i className="fa-solid fa-map-location"></i>{" "}
                <Link
                  to={"https://maps.app.goo.gl/WhEjaXoj8dB6avT38"}
                  target="_blank"
                >
                  <p>
                    DN-30, DN block, Sector v, Saltlake City, Kolkata - 700091
                  </p>
                </Link>
              </div>
              <div className="address">
                <i className="fa-solid fa-phone"></i>
                <div className="phone_no_group" style={{ width: "100%" }}>
                  <Link to={"tel:+916292276444"}>
                    <p>Toll Free Number. : +91 62922-76444</p>
                  </Link>
                  <Link to={"https://wa.me/+918100971969"} target="_blank">
                    <p>Whatsapp Number. : +91 81009-71969 </p>
                  </Link>
                  <Link to={"tel:+03335081849"}>
                    <p>Customer Grievance No. : (033) 3508-1849 </p>
                  </Link>
                </div>
              </div>
              <div className="address" style={{ width: "100%" }}>
                <i className="fa-solid fa-envelope"></i>{" "}
                <Link
                  to="https:///mail.google.com/mail/?view=cm&fs=1&to=contactus@sampurna.co.in"
                  target="_blank"
                >
                  <p style={{ width: "100%" }}>
                    Email : contactus@sampurna.co.in
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactComp;
